* {
    box-sizing: border-box;
}

body {
    font-family: 'IBM Plex Sans', sans-serif;
}

html,
body {
    height: 100%;
}

a {
    text-decoration: none;
}

a,
a:visited {
    color: inherit;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.generalDiv {
    background: linear-gradient(138deg, #22343c 0.08%, #1f2e35 100%);
    height: 100%;
}

@media screen and (max-width: 768px) {
    .generalDiv {
        background: #2a3c44;
    }
}

::-webkit-scrollbar {
    display: none;
}