.card-about {
  width: 95%;
  height: 80vh;
  margin: auto;
  margin-top: 130px;
  margin-bottom: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background: #2a3c44;
  overflow-x: hidden;
  overflow: hidden;
}

.card-about::-webkit-scrollbar {
  display: none;
}

.title-holder-about {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  height: 15%;
}

.title-holder-about h1.title {
  font-weight: bold;
  font-size: 5vh;
}

.aboutContainer {
  align-self: center;
  width: 90%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-Profile {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.card-Profile {
  width: 95%;
  height: 90%;
  background: transparent;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: flex-start;
}

.card-header-Profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.profile-images {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.avatar-Profile {
  width: 100%;
  height: 100%;
}

.profile-images img {
  max-width: 90%;
  max-height: 90%;
  object-fit: cover;
  border-radius: 50%;
}

.profileText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 30%;
}

.profile-name h1 {
  margin-top: 15px;
  font-size: 5vh;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF;
}

.profile-role h1 {
  margin-top: 2vh;
  color: #FFFFFF;
  font-size: 2.5vh;
  font-weight: 500;
  text-align: center;
}

.aboutDescription {
  height: 90%;
  width: 65%;
  overflow-y: scroll;
}

.aboutDescription::-webkit-scrollbar {
  display: none;
}

.aboutDescription p {
  font-family: "Poppins-Normal";
  line-height: normal;
  text-align: justify;
  color: #ffffff;
  font-size: 2.2vh;
}

@media screen and (max-width: 768px) {
  .card-about {
    width: 100%;
    height: 92vh;
    border-radius: 0;
    box-shadow: none;
    margin-top: 80px;
    margin-bottom: 0px;
  }

  .title-holder-about {
    max-width: 100%;
  }

  .aboutContainer {
    flex-direction: column;
    width: 100%;
  }

  .container-Profile {
    width: 100%;
    height: 20%;
    justify-content: center;
  }

  .card-Profile {
    width: 90%;
    height: 100%;
  }

  .card-header-Profile {
    flex-direction: row;
  }

  .profile-images {
    width: 30%;
    height: 100%;
  }

  .profileText {
    width: 65%;
  }

  .profile-name h1 {
    font-size: 4vh;
    margin-top: 0px;
  }

  .profile-role h1 {
    margin-top: 1vh;
    font-size: 2vh;
  }

  .aboutDescription {
    width: 90%;
    height: 70%;
    margin-top: 15px;
    margin-bottom: 100px;
  }
}
