.buttonTopBar {
  color: #aeaeae;
  background: #2a3c44;
  width: 9em;
  height: 3.5em;
  border: none;
  border-radius: 0.6rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  margin-left: 5px;
  margin-right: 5px;
}

.buttonTopBar .text {
  font-family: Poppins-Normal;
  font-size: 15px;
}

.buttonTopBar.active {
  color: #ffffff;
  background: #ff464f;
}

.buttonTopBar span:not(:nth-child(6)) {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
  width: 30px;
  background-color: #623a42;
  border-radius: 0.6rem;
  transition: 0.6s ease;
}

.buttonTopBar span:nth-child(6) {
  position: relative;
}

.buttonTopBar span:nth-child(1) {
  transform: translate(-3.3em, -5em);
}

.buttonTopBar span:nth-child(2) {
  transform: translate(-6em, 2em);
}

.buttonTopBar span:nth-child(3) {
  transform: translate(-0.2em, 2.2em);
}

.buttonTopBar span:nth-child(4) {
  transform: translate(3.5em, 2em);
}

.buttonTopBar span:nth-child(5) {
  transform: translate(4.2em, -3.8em);
}

.buttonTopBar:hover span:not(:nth-child(6)) {
  transform: translate(-50%, -50%) scale(5);
  transition: 1.5s ease;
}

.buttonTopBar:hover .text {
  color: #ffffff;
  transition: 1s ease;
}

@media screen and (max-width: 768px) {
  .buttonTopBar span:not(:nth-child(6)) {
    transform: none;
    height: 0px;
    width: 0px;
  }

  .buttonTopBar {
    color: #aeaeae;
    background-color: #222425;
    width: 100%;
    height: 6em;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .buttonTopBar .text {
    font-size: 20px;
  }
}
