@font-face {
  font-family: "Poppins-Lighter";
  src: url("../assets/fonts/Poppins-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Lighter-Italic";
  src: url("../assets/fonts/Poppins-LightItalic.ttf") format("truetype");
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: "Poppins-Normal";
  src: url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Normal-Italic";
  src: url("../assets/fonts/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Bold-Italic";
  src: url("../assets/fonts/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Poppins-Bolder";
  src: url("../assets/fonts/Poppins-Black.ttf") format("truetype");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Bolder-Italic";
  src: url("../assets/fonts/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: bolder;
  font-style: italic;
}
