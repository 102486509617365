.cardHome {
  width: 95%;
  height: 80vh;
  margin: auto;
  margin-top: 130px;
  margin-bottom: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to right top,
    #904857,
    #ff464f,
    #ff575f,
    #ff464f,
    #904857
  );
  background-size: 500%;
  animation: gradientAnimation 5s ease infinite;
  overflow-x: hidden;
}

.contentHome {
  flex: 1 1 auto;
  min-height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentHome .title-holderHome {
  color: #fff;
  text-align: center;
  margin-bottom: 24px;
}

.contentHome .title-holderHome h1 {
  font-weight: bold;
  font-size: 3vh;
  margin-bottom: 12px;
  margin-top: 5vh;
}

.contentHome .title-holderHome h1.title {
  font-size: 12vh;
}

.contentHome .socialSection {
  display: flex;
  justify-content: center;
}

.contentHome .socialSection a {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 5vh;
  color: #ffffff;
}

@media screen and (max-width: 512px) {
  .cardHome {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
  }
}

@media screen and (max-width: 768px) {
  .contentHome .title-holderHome {
    max-width: 80%;
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
