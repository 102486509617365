/* Estilos para la barra de navegación */
nav {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 100;
  width: 95%;
  height: 80px;
  background-color: transparent;
  border-radius: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0;
}

/* Estilos para los elementos de la barra de navegación */
#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbar li:hover,
#navbar li.active {
  background: linear-gradient(166deg,
      rgba(250, 82, 82, 1) 47%,
      rgba(221, 36, 118, 1) 100%);
}

/* Estilos para el logo */
.logo {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.5em;
  margin-left: 50px;
  transition: opacity 0.05s ease;
}

.logo .icon {
  width: 40px;
  fill: #fff;
}

.logo:hover {
  opacity: 0.75;
}

/* Estilos para el menú móvil */
#mobile {
  display: none;
}

#mobile i {
  color: #fff;
}

/* Media query para pantallas de hasta 768px */
@media screen and (max-width: 768px) {
  nav {
    width: 100%;
    top: 0px;
    padding: 0 30px;
    border-radius: 0;
  }

  .navHome {
    background-color: #222425;
  }

  #navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 79px;
    right: -1000px;
    width: 100%;
    height: 92vh;
    background: #2b2b2b;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: 0.3s ease-in-out;
    z-index: 100;
    border-radius: 0 0 0.6rem 0.6rem;
  }

  #navbar.active {
    right: 0;
  }

  #mobile {
    display: block;
  }

  #mobile i {
    font-size: 30px;
    cursor: pointer;
  }

  .logo {
    margin-left: 0;
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
}

.btn-container {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner {
  margin: 0px;
  width: 100px;
  height: 26px;
  background-color: #ff464f;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
  display: block;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: 15px;
  font-weight: 600;
  top: 7px;
  right: 20px;
  color: #222;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 50px;
  height: 12px;
  background: #fff;
  border-radius: 26px;
  position: absolute;
  font-size: 15px;
  display: flex;
  justify-content: center;
  left: 2px;
  top: 2px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 5px 0px;
  color: #222;
  font-weight: 600;
}

.btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner {
  background-color: #ff464f;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 48px;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 20px;
}