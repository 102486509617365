.card-resume {
  width: 95%;
  height: 80vh;
  margin: auto;
  margin-top: 130px;
  margin-bottom: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background: #2a3c44;
  overflow-x: hidden;
}

.card-resume::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 512px) {
  .card-resume {
    width: 100%;
    height: 90vh;
    border-radius: 0;
    box-shadow: none;
    margin-top: 80px;
    margin-bottom: 0px;
  }
}

.content-resume {
  flex-direction: column;
  align-self: center;
  width: 90%;
  /*   height: 85%;
 */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* .title-holder-resume {
  color: #fff;
  text-align: center;
}

.title-holder-resume h1.title {
  font-weight: bold;
  font-size: 50px;
  margin-bottom: 12px;
  margin-top: 30px;
} */

.title-holder-resume {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  height: 15%;
}

.title-holder-resume h1.title {
  font-weight: bold;
  font-size: 5vh;
}

@media screen and (max-width: 768px) {
  .title-holder-resume {
    max-width: 100%;
  }
}

/* RADIO SECTION */
.radio-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  margin-bottom: 20px;
}

.radio-inputs>* {
  margin: 6px;
}

.radio-input:checked+.radio-tile {
  border-color: #ff464f;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #ff464f;
}

.radio-input:checked+.radio-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #ff464f;
  border-color: #ff464f;
}

.radio-input:checked+.radio-tile .radio-icon svg {
  fill: #ff464f;
}

.radio-input:checked+.radio-tile .radio-label {
  color: #ff464f;
}

.radio-input:focus+.radio-tile {
  border-color: #ff464f;
}

.radio-input:focus+.radio-tile:before {
  transform: scale(1);
  opacity: 1;
}

.radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-height: 80px;
  border-radius: 0.5rem;
  border: 2px solid #636772;
  background: linear-gradient(138deg, #2a3c44 0.08%, #23343c 100%);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}

.radio-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid #636772;
  background-color: #222425;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
}

.radio-tile:hover {
  border-color: #623a42;

  .radio-icon svg {
    fill: #623a42;
  }

  .radio-label {
    color: #623a42;
  }
}

.radio-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.radio-icon svg {
  width: 2rem;
  height: 2rem;
  fill: #aeaeae;
}

.radio-label {
  color: #aeaeae;
  transition: 0.375s ease;
  text-align: center;
  font-size: 13px;
}

.radio-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* TIME LINE */
.container-fluid {
  width: 100%;
  margin-bottom: 50px;
  overflow: scroll;
}

.container-fluid .row {
  height: 60vh;
}

.container-fluid .row:nth-child(even) {
  background: #f1f4f5;
}

/*-- GENERAL STYLES ------------------------------*/

.timeline {
  line-height: 1.4em;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.timeline h1,
.timeline h2,
.timeline h3,
.timeline h4,
.timeline h5,
.timeline h6 {
  line-height: inherit;
}

h3.timeline-title {
  font-family: "Poppins-bold";
  font-size: 20px;
  color: #ff464f;
  color: #ffffff;
  margin-top: 0;
}

h3.timeline-subtitle {
  font-family: "Poppins-normal-italic";
  font-size: 12px;
  color: #ffffff;
  margin-top: 0;
}

/*----- TIMELINE ITEM -----*/
.timeline-item {
  padding-left: 40px;
  position: relative;
}

.timeline-item:last-child {
  padding-bottom: 0;
}

/*----- TIMELINE INFO -----*/
.timeline-info {
  display: flex !important;
  flex-direction: column !important;

  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 3px;
  margin: 0 0 0.5em 0;
  text-transform: uppercase;
  white-space: nowrap;
}

/*----- TIMELINE MARKER -----*/
.timeline-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px;
}

.timeline-marker:before {
  background: #ff464f;
  border: 3px solid transparent;
  border-radius: 100%;
  content: "";
  display: block;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 0;
  width: 15px;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}

.timeline-marker:after {
  content: "";
  width: 3px;
  background: #ccd5db;
  display: block;
  position: absolute;
  top: 24px;
  bottom: 0;
  left: 6px;
}

.timeline-item:last-child .timeline-marker:after {
  content: none;
}

.timeline-item:not(.period):hover .timeline-marker:before {
  background: transparent;
  border: 3px solid #ff464f;
}

/*----- TIMELINE CONTENT -----*/
.timeline-content {
  padding-bottom: 40px;
}

.timeline-content p {
  margin-bottom: 0;
  color: #ffffff;
}

/*---------------------------------------------- MOD: TIMELINE SPLIT ----------------------------------------------*/
@media (min-width: 768px) {

  .timeline-split .timeline,
  .timeline-centered .timeline {
    display: table;
  }

  .timeline-split .timeline-item,
  .timeline-centered .timeline-item {
    display: table-row;
    padding: 0;
  }

  .timeline-split .timeline-info,
  .timeline-centered .timeline-info,
  .timeline-split .timeline-marker,
  .timeline-centered .timeline-marker,
  .timeline-split .timeline-content,
  .timeline-centered .timeline-content,
  .timeline-split .period .timeline-info,
  .timeline-centered .period .timeline-info {
    display: table-cell;
    vertical-align: top;
  }

  .timeline-split .timeline-marker,
  .timeline-centered .timeline-marker {
    position: relative;
  }

  .timeline-split .timeline-content,
  .timeline-centered .timeline-content {
    padding-left: 30px;
  }

  .timeline-split .timeline-info,
  .timeline-centered .timeline-info {
    padding-right: 30px;
  }

  .timeline-split .period .timeline-title,
  .timeline-centered .period .timeline-title {
    position: relative;
    left: -45px;
  }
}

/*---------------------------------------------- MOD: TIMELINE CENTERED ----------------------------------------------*/
@media (min-width: 992px) {

  .timeline-centered,
  .timeline-centered .timeline-item,
  .timeline-centered .timeline-info,
  .timeline-centered .timeline-marker,
  .timeline-centered .timeline-content {
    display: block;
    margin: 0;
    padding: 0;
  }

  .timeline-centered .timeline-item {
    padding-bottom: 40px;
    overflow: hidden;
  }

  .timeline-centered .timeline-marker {
    position: absolute;
    left: 50%;
    margin-left: -7.5px;
  }

  .timeline-centered .timeline-info,
  .timeline-centered .timeline-content {
    width: 50%;
  }

  .timeline-centered>.timeline-item:nth-child(odd) .timeline-info {
    float: left;
    text-align: right;
    padding-right: 30px;
  }

  .timeline-centered>.timeline-item:nth-child(odd) .timeline-content {
    float: right;
    text-align: left;
    padding-left: 30px;
  }

  .timeline-centered>.timeline-item:nth-child(even) .timeline-info {
    float: right;
    text-align: left;
    padding-left: 30px;
  }

  .timeline-centered>.timeline-item:nth-child(even) .timeline-content {
    display: flex;
    flex-direction: column;
    float: left;
    text-align: right;
    padding-right: 30px;
    align-items: flex-end;
  }

  .timeline-centered>.timeline-item.period .timeline-content {
    float: none;
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .timeline-centered .timeline-item.period {
    padding: 50px 0 90px;
  }

  .timeline-centered .period .timeline-marker:after {
    height: 30px;
    bottom: 0;
    top: auto;
  }

  .timeline-centered .period .timeline-title {
    left: auto;
  }
}

/*---------------------------------------------- MOD: MARKER OUTLINE ----------------------------------------------*/
.marker-outline .timeline-marker:before {
  background: transparent;
  border-color: #ff6b6b;
}

.marker-outline .timeline-item:hover .timeline-marker:before {
  background: #ff6b6b;
}

/* BUTTON MORE */

.button-more {
  margin-top: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #623a42;
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.svgIcon-more {
  transition-duration: 0.3s;
  font-weight: bold;
  color: #ffffff;
}

.svgIcon-more path {
  fill: white;
}

.button-more:hover {
  width: 140px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: #ff464f;
  align-items: center;
}

.button-more:hover .svgIcon-more {
  width: 0px;
  transition-duration: 0.3s;
  transform: translateY(60%);
}

.button-more::before {
  position: absolute;
  top: -25px;
  content: attr(data-text);
  color: white;
  transition-duration: 0.3s;
  font-size: 2px;
}

.button-more:hover::before {
  font-size: 13px;
  opacity: 1;
  transform: translateY(30px);
  transition-duration: 0.3s;
}

/* CARD Modal */
.card {
  width: 100%;
  height: 64.5vh;
  margin: 0 auto;
  background: linear-gradient(138deg, #3c4e56 0.08%, #3c4e56 100%);
  border-radius: 8px;
  z-index: 1;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

@media screen and (max-width: 512px) {
  .card {
    height: 100vh;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 0.45s;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.card .card__content {
  width: 90%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.card .card__content .inner {
  display: flex;
  justify-content: space-between;
}

.tools {
  display: flex;
  align-items: center;
  padding: 9px;
}

.circle {
  padding: 0 5px;
}

.box {
  display: inline-block;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 1px;
  border-radius: 50%;
}

.red {
  background-color: #ff605c;
}

.yellow {
  background-color: #ffbd44;
}

.green {
  background-color: #00ca4e;
}

/* MODAL CHIPS */

.chip {
  display: inline-flex;
  flex-direction: row;
  background-color: #ff565e;
  border: none;
  cursor: default;
  height: 36px;
  outline: none;
  padding: 0;
  font-size: 14px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 16px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.chip-content {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 1.5vh;
}

.companyName {
  color: #ffffff;
  font-size: 5vh;
  font-family: "Poppins-bold";
}

.jobTitle {
  color: #ffffff;
  font-size: 2.5vh;
  font-family: "Poppins-bold";
  margin-top: 0px;
}

.dates {
  color: #ffffff;
  font-size: 2vh;
  font-family: "Poppins-normal-italic";
  margin-top: 10px;
}

.time-elapsed {
  color: #ffffff;
  font-size: 2vh;
  font-family: "Poppins-normal";
  margin-top: 5px;
}

.bundleDescription {
  margin-top: 25px;
}

.description {
  color: #ffffff;
  font-size: 2vh;
  font-family: "Poppins-normal";
  margin-top: 5px;
  text-align: justify;
  line-height: normal;
}

.chipsBundleTitle {
  color: #ffffff;
  font-size: 2vh;
  font-family: "Poppins-Lighter-italic";
  margin-top: 40px;
  text-align: justify;
}

.chipsBundle {
  margin-top: 25px;
}